import React from "react";
import { CardResult } from "../CardResult";
import { BlogReturn } from "../BlogReturn";

interface Props {
  results?: BlogReturn;
}

export const BlogResults = ({ results }: Props) => {
  if (results && results?.totalCnt > 0) {
    return (
      <section id="variable">
        <div className="container mt-lg-9 mt-md-7 mt-6 news-events-listing">
          <div className="row">
            {results.resultList.map((r) => {
              return <CardResult key={r.key} card={r} isNews={false} />;
            })}
          </div>
        </div>
      </section>
    );
  }

  return (
    <div className="justify-content-center text-center mt-5">
      <h3 className="text-secondary">Sorry, No Results Were Found</h3>
    </div>
  );
};
