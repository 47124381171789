/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { ProductTableHeader } from "./ProductTableHeader";
import { ProductTableRow } from "./ProductTableRow";
import { ProductFilterApplied } from "./ProductFilterApplied";
import { ProductFilterRow } from "./ProductFilterRow";
import { Pagination } from "../Pagination";
import { Modal } from "../Modal";
import "../css/hero-search.css";
import {
  getProductFilterReturnAsync,
  ProductFilterReturn,
  ProductFilterBindingModel,
  ProductFilterContext,
  DistributorContext,
} from "../ProductFilterReturn";
import { Loading } from "../Loading";
import { lookForTable } from "../init-sticky-header";

interface Props {
  sourceKey: string;
  pageSize: number;
  urlParams: string;
}

export const ProductFilterRoot = ({
  sourceKey,
  pageSize,
  urlParams,
}: Props) => {
  const defaultBindingModel: ProductFilterBindingModel = {
    Key: sourceKey,
    KeywordSearch: "",
    PageSize: pageSize,
    PageNumber: 0,
    SortKey: "",
    SortDirection: "",
    FilterList: [],
  };
  const [productFilterData, setProductFilterData] =
    React.useState<ProductFilterBindingModel>(defaultBindingModel);
  const [initialFiltersSet, setInitialFiltersSet] = React.useState(!urlParams);
  const [result, setResult] = React.useState<ProductFilterReturn>();
  const [loading, setLoading] = React.useState(true);
  const [distributor, setDistributor] = React.useState("");
  const [distributorLoading, setDistributorLoading] = React.useState(false);

  const updatePageNumber = (pageNumber: number) => {
    setProductFilterData({
      ...productFilterData,
      PageNumber: pageNumber,
    });
  };

  React.useEffect(() => {
    lookForTable(0);
  });

  React.useEffect(() => {
    setLoading(true);
    const doGetProductFilterReturn = async (
      binding: ProductFilterBindingModel
    ) => {
      const result = await getProductFilterReturnAsync(binding);
      if (!initialFiltersSet) {
        const filtersArr = result?.filterList.reduce<Array<any>>(
          (acc, cur) => [...acc, ...cur.cellList],
          []
        );
        setProductFilterData({
          ...productFilterData,
          FilterList: urlParams
            ? urlParams.split(";").map((p) => {
                const split = p.split("=");
                const filter = filtersArr?.find((f) => f.group === split[0]);
                let value = split[1];
                let rawValue = "";
                let valueMax = "";
                if (filter.type === 8 || filter.type === 9) {
                  rawValue = split[1];
                }
                if (filter.type === 18) {
                  rawValue = value = split[1].split("|")[0].split(":")[1];
                }
                if (filter.type === 21) {
                  const rangeSplit = split[1].split("|");
                  value = rangeSplit[0];
                  valueMax = rangeSplit[1];
                }
                return {
                  name: split[0],
                  value,
                  rawValue,
                  nameMin: filter?.minName || "",
                  nameMax: filter?.maxName || "",
                  valueMax,
                  filterType: filter?.type || 0,
                };
              })
            : [],
        });
        setInitialFiltersSet(true);
      } else {
        setResult(result);
        setLoading(false);
      }
    };
    doGetProductFilterReturn(productFilterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFilterData]);

  return (
    <div>
      <section id="variable">
        <Modal
          show={distributor ? true : false}
          onClose={() => {
            setDistributor("");
          }}
        >
          <div className="modal-body iframe-body">
            <Loading hide={!distributorLoading} />
            <iframe
              className="inv-iframe"
              title="Distributor Inventory"
              src={distributor}
              onLoad={() => setDistributorLoading(false)}
              style={{ display: distributorLoading ? "none" : "block" }}
            ></iframe>
          </div>
        </Modal>
        <ProductFilterContext.Provider
          value={{ productFilterData, setProductFilterData }}
        >
          <div className="container">
            <div className="row product-filters mt-5">
              {result?.filterList?.length ? (
                <div className="col-12">
                  <h4 className="text-secondary">Filters</h4>
                </div>
              ) : null}
              <ProductFilterRow data={result?.filterList || []} />
            </div>
            <ProductFilterApplied data={result?.filterList || []} />
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="container">
              <div className="row product-results mt-4">
                <div className="col-12">
                  <h4 className="text-secondary">Results</h4>
                  <p className="mb-0 small">{result?.totalCnt} Results Found</p>
                </div>
                <div className="col-12">
                  <div id={result?.key} className="table-responsive">
                    <table className="table product-table">
                      <DistributorContext.Provider
                        value={{
                          distributor,
                          setDistributor,
                          setDistributorLoading,
                        }}
                      >
                        <ProductTableHeader data={result!.headerList} />
                        <ProductTableRow data={result!.productList} />
                      </DistributorContext.Provider>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="container mb-6">
            <Pagination
              count={result?.totalCnt || 0}
              pages={result?.pageCnt || 0}
              currentPage={result?.pageCur || 0}
              updatePageNumber={updatePageNumber}
            />
          </div>
        </ProductFilterContext.Provider>
      </section>
    </div>
  );
};
