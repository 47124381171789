import React, { useEffect } from "react";
import {
  ProductFilterReturnFilterCell,
  ProductFilterContextModel,
  useProductFilterContext,
  ProductFilterBindingFilter,
} from "../ProductFilterReturn";

interface Props {
  data: ProductFilterReturnFilterCell;
}

export const ProductFilterInputMultiselect = ({ data }: Props) => {
  const { productFilterData, setProductFilterData } =
    useProductFilterContext() as ProductFilterContextModel;

  const [checkedSet, setCheckedSet] = React.useState<Set<string>>(
    new Set<string>()
  );

  useEffect(() => {
    const existingFilter = productFilterData.FilterList.find(
      (f) => f.name === data.group
    );

    const currentCheckedSet = existingFilter
      ? new Set<string>(existingFilter.value.split("|"))
      : new Set<string>();

    setCheckedSet(currentCheckedSet);
  }, [productFilterData, data]);

  const existingFilter = () => {
    return (
      productFilterData &&
      productFilterData.FilterList.find((f) => f.name === data.group)
    );
  };

  return (
    <div className="mb-3 d-flex">
      <div className="flex-grow-1 w-100">
        <label className="form-label">{data.displayName}</label>
        {data.optionList.map((d) => (
          <div className="form-check" key={d.key}>
            <input
              className="form-check-input"
              type="checkbox"
              name={data.key}
              id={d.key}
              value={d.value}
              checked={checkedSet.has(d.value)}
              disabled={!d.isEnabled}
              onChange={(e) => {
                const newCheckedSet = new Set(checkedSet);
                if (e.target.checked) {
                  newCheckedSet.add(d.value);
                } else {
                  newCheckedSet.delete(d.value);
                }
                const newValue = Array.from(newCheckedSet).join("|");
                let newFilterList = [...productFilterData.FilterList];
                if (existingFilter() && newValue !== "") {
                  newFilterList = productFilterData.FilterList.map(
                    (f: ProductFilterBindingFilter) => {
                      if (f.name === data.group) {
                        return {
                          ...f,
                          value: newValue,
                        };
                      }
                      return f;
                    }
                  );
                } else {
                  if (newValue !== "") {
                    const newFilter: ProductFilterBindingFilter = {
                      name: data.group,
                      nameMin: data.minName,
                      nameMax: data.maxName,
                      value: newValue,
                      valueMax: "",
                      filterType: data.type,
                    };
                    newFilterList.push(newFilter);
                  } else {
                    newFilterList = newFilterList.filter(
                      (f: ProductFilterBindingFilter) => f.name !== data.group
                    );
                  }
                }

                setProductFilterData({
                  ...productFilterData,
                  FilterList: newFilterList,
                  PageNumber: 0,
                });
              }}
            />
            <label className="form-check-label" htmlFor={d.key}>
              {d.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
