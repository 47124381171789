import React from "react";
import {
  getVideoReturnAsync,
  VideoReturn,
  VideoBindingModel,
} from "../VideoReturn";
import { VideoResults } from "./VideoResults";
import { Pagination } from "../Pagination";
import { Loading } from "../Loading";

interface Props {
  searchKey: string;
  pageSize: number;
}

export const VideoSearchRoot = ({ searchKey = "", pageSize }: Props) => {
  const defaultVideoModel: VideoBindingModel = {
    geo: "",
    yr: "",
    kywrd: searchKey,
    pgsz: pageSize,
    pgnum: 0,
  };

  const [videoSearchData, setVideoSearchData] =
    React.useState<VideoBindingModel>(defaultVideoModel);
  const [videoResult, setVideoResult] = React.useState<VideoReturn>();
  const [loading, setLoading] = React.useState(true);
  const [search, setSearch] = React.useState(searchKey);
  const [geo, setGeo] = React.useState("");
  const [year, setYear] = React.useState("");

  React.useEffect(() => {
    setLoading(true);
    const doGetVideoReturn = async (binding: VideoBindingModel) => {
      const result = await getVideoReturnAsync(binding);
      setVideoResult(result);
      setLoading(false);
    };
    doGetVideoReturn(videoSearchData);
  }, [videoSearchData]);

  return (
    <>
      <section id="variable">
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-md-8">
              <form
                className="search search-hero"
                onSubmit={(e) => {
                  e.preventDefault();
                  setVideoSearchData({
                    ...videoSearchData,
                    kywrd: search,
                    geo: geo,
                    yr: year,
                    pgnum: 0,
                  });
                }}
              >
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Search Video"
                    placeholder="Search Video"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary p-3 btn-search"
                  >
                    <i className="bi bi-search"></i>
                  </button>
                </div>
              </form>
            </div>
            <div className="col-12 col-md-2">
              <select
                className="form-select search-hero-filter mt-3 mt-md-0"
                aria-label="Search Video"
                value={geo}
                onChange={(e) => {
                  setGeo(e.target.value);
                  setVideoSearchData({
                    ...videoSearchData,
                    kywrd: search,
                    geo: e.target.value,
                    yr: year,
                    pgnum: 0,
                  });
                }}
              >
                {videoResult?.filter?.geoLocationOptionList.map((opt) => (
                  <option
                    key={opt.key}
                    value={opt.value}
                    disabled={!opt.isEnabled}
                  >
                    {opt.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-2">
              <div className="d-flex flex-column">
                <select
                  className="form-select search-hero-filter mt-3 mt-md-0"
                  aria-label="Search Video"
                  value={year}
                  onChange={(e) => {
                    setYear(e.target.value);
                    setVideoSearchData({
                      ...videoSearchData,
                      kywrd: search,
                      geo: geo,
                      yr: e.target.value,
                      pgnum: 0,
                    });
                  }}
                >
                  {videoResult?.filter?.yearOptionList.map((opt) => (
                    <option
                      key={opt.key}
                      value={opt.value}
                      disabled={!opt.isEnabled}
                    >
                      {opt.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </section>
      {loading ? <Loading /> : <VideoResults results={videoResult} />}
      {loading ? null : (
        <div className="container">
          <Pagination
            count={videoResult?.totalCnt || 0}
            pages={videoResult?.pageCnt || 0}
            currentPage={videoResult?.pageCur || 0}
            updatePageNumber={(pageNumber: number) =>
              setVideoSearchData({ ...videoSearchData, pgnum: pageNumber })
            }
          />
        </div>
      )}
    </>
  );
};
