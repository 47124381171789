import React, { useEffect } from "react";
import { ProductSearchRoot } from "./product/ProductSearchRoot";
import { ResourceSearchRoot } from "./resource/ResourceSearchRoot";
import { ContentSearchRoot } from "./content/ContentSearchRoot";
import "./css/hero-search.css";
import {
  getProductSearchReturnAsync,
  ProductSearchReturn,
  ProductSearchBindingModel,
  ProductSearchContext,
} from "./ProductSearchReturn";
import {
  getResourceReturnAsync,
  ResourceReturn,
  ResourceBindingModel,
} from "./ResourceReturn";
import {
  getContentReturnAsync,
  ContentReturn,
  ContentBindingModel,
} from "./ContentReturn";
import { Pagination } from "./Pagination";
import { Loading } from "./Loading";
import { lookForTable } from "./init-sticky-header";

interface Props {
  searchKey: string;
  pageSize: number;
}

export const SiteSearchRoot = ({ searchKey = "", pageSize }: Props) => {
  const [keywordSearch, setKeywordSearch] = React.useState(true);

  const defaultProductModel: ProductSearchBindingModel = {
    Key: "",
    KeywordSearch: searchKey,
    PageSize: pageSize,
    PageNumber: 0,
    SortKey: "",
    SortDirection: "",
    FilterList: [],
  };
  const [productSearchData, setProductSearchData] =
    React.useState<ProductSearchBindingModel>(defaultProductModel);
  const [productResult, setProductResult] =
    React.useState<ProductSearchReturn>();
  const [productFiltersOpen, setProductFiltersOpen] = React.useState<
    Set<string>
  >(new Set<string>());
  const [isNewSearch, setIsNewSearch] = React.useState<boolean>(true);
  const [isStaleData, setIsStaleData] = React.useState<boolean>(true);

  const defaultResourceModel: ResourceBindingModel = {
    keyword: searchKey,
    pageSize: pageSize,
  };
  const [resourceSearchData, setResourceSearchData] =
    React.useState<ResourceBindingModel>(defaultResourceModel);
  const [resourceResult, setResourceResult] = React.useState<ResourceReturn>();

  const defaultContentModel: ContentBindingModel = {
    keyword: searchKey,
    pageSize: pageSize,
  };
  const [contentSearchData, setContentSearchData] =
    React.useState<ContentBindingModel>(defaultContentModel);
  const [contentResult, setContentResult] = React.useState<ContentReturn>();

  const [loadingProducts, setLoadingProducts] = React.useState(true);
  const [loadingResources, setLoadingResources] = React.useState(true);
  const [loadingContent, setLoadingContent] = React.useState(true);
  const [search, setSearch] = React.useState(searchKey);
  const [tab, setTab] = React.useState("Products");

  useEffect(() => {
    if (!loadingProducts && !loadingResources && !loadingContent) {
      if (keywordSearch) {
        const hasProductResults = productResult?.totalCnt;
        const hasResourceResults = resourceResult?.totalCnt;
        const hasContentResults = contentResult?.totalCnt;
        if (tab === "Products") {
          if (!hasProductResults) {
            if (hasResourceResults) {
              setTab("Resources");
            } else {
              if (hasContentResults) {
                setTab("Content");
              }
            }
          }
        } else if (tab === "Resources") {
          if (!hasResourceResults) {
            if (hasProductResults) {
              setTab("Products");
            } else {
              if (hasContentResults) {
                setTab("Content");
              }
            }
          }
        } else {
          if (!hasContentResults) {
            if (hasProductResults) {
              setTab("Products");
            } else {
              if (hasResourceResults) {
                setTab("Resources");
              }
            }
          }
        }
        setKeywordSearch(false);
      }
    }
  }, [loadingProducts, loadingResources, loadingContent]);

  useEffect(() => {
    lookForTable(0, 1499);
  });

  useEffect(() => {
    if (productResult?.productOption?.totalCnt && isNewSearch && !isStaleData) {
      const newSet: Set<string> = new Set<string>();
      productResult?.productOption?.optionList?.forEach((filter) => {
        if (
          filter.childrenList &&
          filter.childrenList.reduce((total, child) => total + child.cnt, 0) > 0
        ) {
          newSet.add(filter.key);
        }
      });
      setIsNewSearch(false);
      setProductFiltersOpen(newSet);
    }
  }, [productResult, isNewSearch, isStaleData]);

  useEffect(() => {
    setLoadingProducts(true);
    const doGetProductSearchReturn = async (
      binding: ProductSearchBindingModel
    ) => {
      const result = await getProductSearchReturnAsync(binding);
      setProductResult(result);
      setIsStaleData(false);
      setLoadingProducts(false);
    };
    doGetProductSearchReturn(productSearchData);
  }, [productSearchData]);

  useEffect(() => {
    setLoadingResources(true);
    const doGetResourceReturn = async (binding: ResourceBindingModel) => {
      const result = await getResourceReturnAsync(binding);
      setResourceResult(result);
      setLoadingResources(false);
    };
    doGetResourceReturn(resourceSearchData);
  }, [resourceSearchData]);

  useEffect(() => {
    setLoadingContent(true);
    const doGetContentReturn = async (binding: ContentBindingModel) => {
      const result = await getContentReturnAsync(binding);
      setContentResult(result);
      setLoadingContent(false);
    };
    doGetContentReturn(contentSearchData);
  }, [contentSearchData]);

  const generateSearchResults = () => {
    if (loadingProducts || loadingResources || loadingContent) {
      return <Loading />;
    }
    if (tab === "Products") {
      return (
        <div
          className="tab-pane pt-3 pb-3 fade show active"
          id="products"
          role="tabpanel"
          aria-labelledby="products-tab"
        >
          <ProductSearchContext.Provider
            value={{ productSearchData, setProductSearchData }}
          >
            <ProductSearchRoot
              result={productResult}
              filterGroupsOpen={productFiltersOpen}
              setFilterGroupsOpen={setProductFiltersOpen}
            />
          </ProductSearchContext.Provider>
        </div>
      );
    }
    if (tab === "Resources") {
      return (
        <div
          className="tab-pane pt-3 pb-3 fade show active"
          id="resources"
          role="tabpanel"
          aria-labelledby="resources-tab"
        >
          <ResourceSearchRoot
            result={resourceResult}
            resourceFilterData={resourceSearchData}
            setResourceFilterData={setResourceSearchData}
          />
        </div>
      );
    }
    return (
      <div
        className="tab-pane pt-3 pb-3 fade show active"
        id="content"
        role="tabpanel"
        aria-labelledby="content-tab"
      >
        <ContentSearchRoot
          result={contentResult}
          contentData={contentSearchData}
          setContentData={setContentSearchData}
        />
      </div>
    );
  };

  const generatePagination = () => {
    if (loadingProducts || loadingResources || loadingContent) {
      return null;
    }
    if (tab === "Products") {
      return (
        <div className="row">
          <div className="col-sm-3" />
          <div className="col-sm-9">
            <Pagination
              count={productResult?.totalCnt || 0}
              pages={productResult?.productFilter?.pageCnt || 0}
              currentPage={productResult?.productFilter?.pageCur || 0}
              updatePageNumber={updatePageNumber}
            />
          </div>
        </div>
      );
    }
    if (tab === "Resources") {
      return (
        <div className="row">
          <div className="col-sm-3" />
          <div className="col-sm-9">
            <Pagination
              count={resourceResult?.totalCnt || 0}
              pages={resourceResult?.pageCnt || 0}
              currentPage={resourceResult?.pageCur || 0}
              updatePageNumber={updatePageNumber}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <Pagination
          count={contentResult?.totalCnt || 0}
          pages={contentResult?.pageCnt || 0}
          currentPage={contentResult?.pageCur || 0}
          updatePageNumber={updatePageNumber}
        />
      </div>
    );
  };

  const updatePageNumber = (pageNumber: number) => {
    if (tab === "Products") {
      setProductSearchData({
        ...productSearchData,
        PageNumber: pageNumber,
      });
    } else if (tab === "Resources") {
      setResourceSearchData({
        ...resourceSearchData,
        pageNumber,
      });
    } else {
      setContentSearchData({
        ...contentSearchData,
        pageNumber,
      });
    }
  };

  return (
    <section id="variable">
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <div className="col-12 col-md-8">
              <form
                className="search search-hero"
                onSubmit={(e) => {
                  e.preventDefault();
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    formLocation: "body",
                    event: "search_keyword",
                    searchTerm: search,
                  });
                  setIsNewSearch(true);
                  setIsStaleData(true);
                  setKeywordSearch(true);
                  setProductSearchData({
                    ...productSearchData,
                    KeywordSearch: search,
                    Key: "",
                    PageNumber: 0,
                  });
                  setResourceSearchData({
                    ...defaultResourceModel,
                    keyword: search,
                    pageNumber: 0,
                  });
                  setContentSearchData({
                    ...contentSearchData,
                    keyword: search,
                    pageNumber: 0,
                  });
                }}
              >
                <div className="input-group">
                  <select
                    className="form-select bg-grey-lightest smallest"
                    aria-label="Search Select"
                    value="0"
                    onChange={() => {}}
                  >
                    <option value="0">Keyword/Part #</option>
                    <option value="1">Cross Ref Part</option>
                  </select>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Search Site"
                    placeholder="Search Site"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary p-3 btn-search"
                  >
                    <i className="bi bi-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 mt-5 mb-6">
            <ul
              className="nav nav-tabs flex-column flex-md-row pt-2"
              id="siteSearchTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={
                    tab === "Products"
                      ? "nav-link w-100 px-5 py-4 active"
                      : "nav-link w-100 px-5 py-4"
                  }
                  id="products-tab"
                  type="button"
                  role="tab"
                  aria-controls="products"
                  onClick={() => setTab("Products")}
                >
                  Products ({productResult?.totalCnt || 0})
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    tab === "Resources"
                      ? "nav-link w-100 px-5 py-4 active"
                      : "nav-link w-100 px-5 py-4"
                  }
                  id="resources-tab"
                  type="button"
                  role="tab"
                  aria-controls="resources"
                  onClick={() => setTab("Resources")}
                >
                  Resources ({resourceResult?.totalCnt || 0})
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    tab === "Content"
                      ? "nav-link w-100 px-5 py-4 active"
                      : "nav-link w-100 px-5 py-4"
                  }
                  id="content-tab"
                  type="button"
                  role="tab"
                  aria-controls="content"
                  onClick={() => setTab("Content")}
                >
                  Content ({contentResult?.totalCnt || 0})
                </button>
              </li>
            </ul>
            <div className="tab-content" id="siteSearchTabContent">
              <div className="site-search-results">
                {generateSearchResults()}
                {generatePagination()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
