import React, { useEffect } from "react";
import InputRange, { Range } from "react-input-range";
import {
  ProductFilterReturnFilterCell,
  ProductFilterContextModel,
  useProductFilterContext,
  ProductFilterBindingFilter,
} from "../ProductFilterReturn";

interface Props {
  data: ProductFilterReturnFilterCell;
}

function fixedVal(step: number) {
  if (!step || step === 1) {
    return 0;
  }

  let val: number = 0;
  let stepStr: string[] = step.toString().split(".");
  if (stepStr.length > 1) {
    val = stepStr[1].length;
  }
  return val;
}

export const ProductFilterInputRangeTwoInput = ({ data }: Props) => {
  const { productFilterData, setProductFilterData } =
    useProductFilterContext() as ProductFilterContextModel;

  const [rangeValue, setRangeValue] = React.useState({
    min: +data.optionMin,
    max: +data.optionMax,
  });

  useEffect(() => {
    const existingFilter = productFilterData.FilterList.find(
      (f) => f.name === data.group
    );

    const currentMinValue = existingFilter?.value || data.optionMin;
    const currentMaxValue = existingFilter?.valueMax || data.optionMax;

    setRangeValue({
      min: +currentMinValue,
      max: +currentMaxValue,
    });
  }, [productFilterData, data]);

  const existingFilter = () => {
    return (
      productFilterData &&
      productFilterData.FilterList.find((f) => f.name === data.group)
    );
  };

  return (
    <div className="mb-3 d-flex">
      <div className="flex-grow-1 w-100">
        <label className="form-label">{data.displayName}</label>
        <InputRange
          minValue={+data.optionMin}
          maxValue={+data.optionMax}
          value={rangeValue}
          onChange={(value: any) => {
            const min =
              value.min < +data.optionMin
                ? +data.optionMin
                : +value.min.toFixed(fixedVal(data.optionStep || 1));
            const max =
              value.max > +data.optionMax
                ? +data.optionMax
                : +value.max.toFixed(fixedVal(data.optionStep || 1));
            setRangeValue({ min, max });
          }}
          step={data.optionStep || 1}
          onChangeComplete={(value: any) => {
            let newFilterList = [...productFilterData.FilterList];
            if (
              existingFilter() &&
              (value.min !== +data.optionMin || value.max !== +data.optionMax)
            ) {
              newFilterList = productFilterData.FilterList.map(
                (f: ProductFilterBindingFilter) => {
                  if (f.name === data.group) {
                    return {
                      ...f,
                      value: value.min.toString(),
                      valueMax: value.max.toString(),
                    };
                  }
                  return f;
                }
              );
            } else {
              if (
                value.min !== +data.optionMin ||
                value.max !== +data.optionMax
              ) {
                const newFilter: ProductFilterBindingFilter = {
                  name: data.group,
                  nameMin: data.minName,
                  nameMax: data.maxName,
                  value: value.min.toString(),
                  valueMax: value.max.toString(),
                  filterType: data.type,
                };
                newFilterList.push(newFilter);
              } else {
                newFilterList = newFilterList.filter(
                  (f: ProductFilterBindingFilter) => f.name !== data.group
                );
              }
            }

            setProductFilterData({
              ...productFilterData,
              FilterList: newFilterList,
              PageNumber: 0,
            });
          }}
        />
      </div>
    </div>
  );
};
