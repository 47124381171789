import React from "react";
import { ProductFilterReturnResultCell } from "../ProductFilterReturn";

interface Props {
  data: ProductFilterReturnResultCell;
  productCode: string;
}

export const ProductTableDataImage = ({ data, productCode }: Props) => (
  <img
    src={data.value}
    title={productCode}
    alt={productCode}
    onError={({ currentTarget }) => {
      currentTarget.onerror = null;
      currentTarget.src = "/images/default-product.png";
    }}
  />
);
