import React from "react";
import { VideoReturnResult } from "../VideoReturn";

interface Props {
  card: VideoReturnResult;
}

export const VideoCardResult = ({ card }: Props) => {
  const renderDate = () => {
    return (
      <p className="card-title text-secondary" style={{ marginBottom: "0p" }}>
        {card?.date}
      </p>
    );
  };

  return (
    <div className="col-md-4 col-sm-6 mb-sm-4 mb-3" key={card.key}>
      <div className="card card-background-top">
        <a href={card.ctaURL} className="play-video" target="_blank">
          <div
            className="card-img position-relative p-3 pb-0 pt-0 mb-1"
            style={{
              backgroundImage: `url(
            ${
              card.cardImage.charAt(0) === "~"
                ? card.cardImage.substring(1)
                : card.cardImage
            })`,
            }}
          >
            <div className="btn-play video-play-icon" data-video="1">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 144 144"
                className="video-play-icon-svg"
                xmlSpace="preserve"
              >
                <rect
                  x="-438.2"
                  y="-29.5"
                  className="video-play-icon-st0"
                  width="360"
                  height="203"
                />
                <g id="Homepage">
                  <g
                    id="_x3E__x3D_1020-Large"
                    transform="translate(-438.000000, -5957.000000)"
                  >
                    <g
                      id="Group-8"
                      transform="translate(196.000000, 5672.000000)"
                    >
                      <g
                        id="Group-2"
                        transform="translate(251.000000, 294.000000)"
                      >
                        <circle
                          id="Oval"
                          className="video-play-icon-st1"
                          cx="63"
                          cy="63"
                          r="58"
                        />
                        <polygon
                          id="Triangle"
                          className="video-play-icon-st2"
                          points="89,62.5 49,86 49,39"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </a>
        <div className="card-body">
          {renderDate()}
          <h4 className="card-title text-secondary">{card.name}</h4>
          <p className="card-text">{card.summary}</p>
          <p className="mb-0">
            <a href={card.ctaURL} className="d-flex align-items-center">
              {card.ctaLabel}
              <span>
                <i className="svg-arrow-right ms-3"></i>
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
