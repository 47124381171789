import "floatthead";
const $ = require("jquery");

function makeHeaderSticky() {
  $(".product-table").floatThead({
    top: 0,
  });

  $(".resource-table").floatThead({
    top: 0,
  });
}

export function lookForTable(callCount: number, width: number = 1020) {
  if (window.innerWidth < width) {
    return;
  }
  // found the table, make it sticky
  if ($(".product-table").length > 0 || $(".resource-table").length > 0) {
    makeHeaderSticky();

    // early exit, no need to run again
    return;
  }

  // if this function has been called numerous times, give up
  if (callCount > 25) {
    return;
  }

  setTimeout(() => {
    lookForTable(callCount + 1);
  }, 250);
}
