import { http } from "./http";

export interface VideoReturn {
  status: string;
  message: string;
  pageSize: number;
  pageCnt: number;
  pageCur: number;
  totalCnt: number;
  filter: VideoReturnFilter;
  resultList: VideoReturnResult[];
}

export interface VideoReturnFilter {
  geoLocation: string;
  year: string;
  keyword: string;
  geoLocationOptionList: VideoReturnFilterOption[];
  yearOptionList: VideoReturnFilterOption[];
}

export interface VideoReturnFilterOption {
  key: string;
  name: string;
  value: string;
  isEnabled: boolean;
  isSelected: boolean;
}

export interface VideoReturnResult {
  key: string;
  name: string;
  summary: string;
  cardImage: string;
  ctaLabel: string;
  ctaURL: string;
  date: string;
}

export interface VideoBindingModel {
  geo: string;
  yr: string;
  kywrd: string;
  pgsz: number;
  pgnum: number;
}

export const getVideoReturnAsync = async (
  binding: VideoBindingModel
): Promise<VideoReturn | undefined> => {
  const result = await http<VideoReturn>({
    // eslint-disable-next-line prettier/prettier
    path: `/video/search?geo=${binding.geo || ""}&yr=${
      binding.yr || ""
    }&kywrd=${binding.kywrd || ""}&pgsz=${binding.pgsz || ""}&pgnum=${
      binding.pgnum || ""
    }`,
    method: "GET",
  });
  if (result.ok && result.body) {
    return result.body;
  } else {
    return undefined;
  }
};
