import React from "react";
import {
  ResourceBindingModel,
  ResourceReturnFilter,
  ResourceReturnResult,
} from "../ResourceReturn";

interface Props {
  data: ResourceReturnFilter;
  results: ResourceReturnResult[];
  resourceFilterData: ResourceBindingModel;
  setResourceFilterData: Function;
}

export const ResourceFilterSection = ({
  data,
  results,
  resourceFilterData,
  setResourceFilterData,
}: Props) => (
  <div className="col-12 col-md-3">
    <h4 className="text-secondary">Filters</h4>
    <div className="pt-4 mb-3">
      <label htmlFor="Resource Type" className="form-label">
        Resource Type
      </label>
      <select
        id="Resource Type"
        className="form-select"
        aria-describedby="Resource Type"
        value={data.typeId || ""}
        onChange={(e) => {
          setResourceFilterData({
            ...resourceFilterData,
            typeCategoryId: e.target.value,
            subtypeCategoryId: "",
            location: "",
            pageNumber: 0,
          });
        }}
      >
        {data.typeOptionList.map((d) => (
          <option value={d.value} key={d.key}>
            {d.name}
          </option>
        ))}
      </select>
    </div>
    {data.subtypeOptionList.length > 0 && (
      <div className="mb-3">
        <label htmlFor="Subtype" className="form-label">
          Subtype
        </label>
        <select
          id="Subtype"
          className="form-select"
          aria-describedby="Subtype"
          value={data.subtypeId || ""}
          onChange={(e) => {
            setResourceFilterData({
              ...resourceFilterData,
              subtypeCategoryId: e.target.value,
              location: "",
              pageNumber: 0,
            });
          }}
        >
          {data.subtypeOptionList.map((d) => (
            <option value={d.value} key={d.key}>
              {d.name}
            </option>
          ))}
        </select>
      </div>
    )}
    <div className="mb-3">
      <label htmlFor="Location" className="form-label">
        Locations&nbsp;
        {data.locationOptionList.length <= 1 ? (
          <div
            className="d-inline-block"
            data-tooltip=""
            title="Resource Type is required before selecting a Location"
          >
            <i className="bi bi-info-circle-fill"></i>
          </div>
        ) : null}
      </label>
      <select
        id="Location"
        className="form-select"
        aria-describedby="Location"
        value={data.location || ""}
        disabled={data.locationOptionList.length <= 1}
        onChange={(e) => {
          setResourceFilterData({
            ...resourceFilterData,
            location: e.target.value,
            pageNumber: 0,
          });
        }}
      >
        {data.locationOptionList.map((d) => (
          <option value={d.value} key={d.key}>
            {d.name}
          </option>
        ))}
      </select>
    </div>
    <div className="mb-3">
      <label htmlFor="Product & Solutions" className="form-label">
        Product & Solutions
      </label>
      <select
        id="Product & Solutions"
        className="form-select"
        aria-describedby="Product & Solutions"
        value={data.productId || ""}
        onChange={(e) => {
          setResourceFilterData({
            ...resourceFilterData,
            productCategoryId: e.target.value,
            pageNumber: 0,
          });
        }}
      >
        {data.productOptionList.map((d) => (
          <option value={d.value} key={d.key}>
            {d.name}
          </option>
        ))}
      </select>
    </div>
    <div className="product_filter_applied">
      <button
        type="button"
        className="btn px-2 clear_filters"
        onClick={() => {
          setResourceFilterData({
            ...resourceFilterData,
            typeCategoryId: "",
            subtypeCategoryId: "",
            productCategoryId: "",
            location: "",
            pageNumber: 0,
          });
        }}
      >
        Clear Filters
      </button>
    </div>
  </div>
);
