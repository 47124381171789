import React from "react";
import { ResourceTable } from "./ResourceTable";
import { ResourceFilterSection } from "./ResourceFilterSection";
import { ResourceBindingModel, ResourceReturn } from "../ResourceReturn";

interface Props {
  result: ResourceReturn | undefined;
  resourceFilterData: ResourceBindingModel;
  setResourceFilterData: Function;
}

export const ResourceSearchRoot = ({
  result,
  resourceFilterData,
  setResourceFilterData,
}: Props) => {
  return (
    <>
      <div className="container">
        <div className="row mt-4">
          <ResourceFilterSection
            data={result!.filter}
            results={result!.resultList}
            resourceFilterData={resourceFilterData}
            setResourceFilterData={setResourceFilterData}
          />
          {result && !result.totalCnt ? (
            <div className="col-12 col-sm-9 justify-content-center text-center">
              <h3 className="text-secondary">Sorry, No Results Were Found</h3>
              <p>
                We suggest checking your part number and spelling, or trying a
                more general keyword
              </p>
            </div>
          ) : (
            <div className="col-12 col-sm-9 resource-results">
              <ResourceTable
                data={result!.resultList}
                resourceFilterData={resourceFilterData}
                setResourceFilterData={setResourceFilterData}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
