import React from "react";
import {
  ProductSearchContextModel,
  ProductSearchReturnHeaderCell,
  useProductSearchContext,
} from "../ProductSearchReturn";

interface Props {
  data: ProductSearchReturnHeaderCell[];
}

export const ProductSearchTableHeader = ({ data }: Props) => {
  const { productSearchData, setProductSearchData } =
    useProductSearchContext() as ProductSearchContextModel;

  const { SortKey, SortDirection } = productSearchData;

  const sortIcon = (enabled: boolean, key: string) => {
    if (!enabled) {
      return null;
    }

    if (key === SortKey) {
      if (SortDirection === "ASC") {
        return (
          <span
            className="sort-header"
            onClick={() => {
              setProductSearchData({
                ...productSearchData,
                SortKey: key,
                SortDirection: "DESC",
              });
            }}
          >
            <i className="bi bi-caret-up-fill"></i>
          </span>
        );
      }
      return (
        <span
          className="sort-header"
          onClick={() => {
            setProductSearchData({
              ...productSearchData,
              SortKey: "",
              SortDirection: "",
            });
          }}
        >
          <i className="bi bi-caret-down-fill"></i>
        </span>
      );
    }

    return (
      <span
        className="sort-header sort-hover"
        onClick={() => {
          setProductSearchData({
            ...productSearchData,
            SortKey: key,
            SortDirection: "ASC",
          });
        }}
      >
        <i className="bi bi-caret-up"></i>
        <i className="bi bi-caret-down"></i>
      </span>
    );
  };

  return (
    <thead>
      <tr>
        {data.map((hdr) => (
          <th key={hdr.key}>
            {hdr.name}
            {sortIcon(hdr.isSortEnabled, hdr.key)}
          </th>
        ))}
      </tr>
    </thead>
  );
};
