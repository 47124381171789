import { http } from "./http";
import React from "react";

export interface ProductFilterReturn {
  displayName: string;
  key: string;
  pageSize: number;
  pageCnt: number;
  pageCur: number;
  totalCnt: number;
  filterList: ProductFilterReturnFilterRow[];
  headerList: ProductFilterReturnHeaderCell[];
  productList: ProductFilterReturnResult[];
}

export interface ProductFilterReturnFilterRow {
  key: string;
  cellCnt: number;
  cellList: ProductFilterReturnFilterCell[];
}

export interface ProductFilterReturnFilterCell {
  key: string;
  id: number;
  name: string;
  displayName: string;
  cellCss: string;
  minName: string;
  maxName: string;
  group: string;
  type: number;
  unit: string;
  optionMin: string;
  optionMax: string;
  optionStep: number | null;
  isApplied: boolean;
  appliedValue: string;
  appliedUnitValue: string;
  optionList: ProductFilterReturnFilterOption[];
  unitList: ProductFilterReturnFilterOption[];
}

export interface ProductFilterReturnFilterOption {
  key: string;
  name: string;
  value: string;
  isEnabled: boolean;
  isSelected: boolean;
}

export interface ProductFilterReturnHeaderCell {
  name: string;
  key: string;
  isSortEnabled: boolean;
}

export interface ProductFilterReturnResult {
  key: string;
  productCode: string;
  cellList: ProductFilterReturnResultCell[];
}

export interface ProductFilterReturnResultCell {
  key: string;
  type: string;
  name: string;
  value: string;
  target: string;
  url: string;
}

export interface ProductFilterBindingModel {
  Key: string;
  KeywordSearch: string;
  PageSize: number;
  PageNumber: number;
  SortKey: string;
  SortDirection: string;
  FilterList: ProductFilterBindingFilter[];
}

export interface ProductFilterBindingFilter {
  name: string;
  nameMin: string;
  nameMax: string;
  value: string;
  valueMax: string;
  filterType: number;
  rawValue?: string;
  unitValue?: string;
}

/*
const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
*/

export const getProductFilterReturnAsync = async (
  binding: ProductFilterBindingModel
): Promise<ProductFilterReturn | undefined> => {
  const result = await http<ProductFilterReturn, ProductFilterBindingModel>({
    path: "/search/product-filter",
    method: "POST",
    body: binding,
  });
  if (result.ok && result.body) {
    return result.body;
  } else {
    return undefined;
  }
};

/*
export const getProductFilterReturnOldAsync =
  async (): Promise<ProductFilterReturn> => {
    let productFilterReturn: ProductFilterReturn = {
      displayName: "",
      key: "",
      productCnt: 0,
      pageSize: 0,
      pageCnt: 0,
      pageCur: 0,
      totalCnt: 0,
      filterList: [],
      headerList: [],
      productList: [],
    };

    const input = new FormData();
    input.append("productTypeKey", "RESISTOR");

    const response = await fetch(
      "http://localhost:25292/api/search/product-filter",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(productFilterBindingModelSample),
      }
    );
    productFilterReturn = await response.json();
    return productFilterReturn;
  };
*/

/*
export const getProductFilterReturn = (): ProductFilterReturn => {
  return productFilterReturnSample;
};
*/

/*
const productFilterBindingModelSample: ProductFilterBindingModel = {
  key: "RESISTOR",
  keywordSearch: "",
  pageSize: 50,
  pageNumber: 1,
  orderKey: "",
  orderDirection: "",
  filterList: [],
};
*/

// export const productFilterBindingModelSample: ProductFilterBindingModel = {
//   key: "RESISTORS",
//   keywordSearch: "",
//   pageSize: 5,
//   pageNumber: 0,
//   sortKey: "",
//   sortDirection: "",
//   filterList: [
//     {
//       name: "Application",
//       value: "High power",
//       filterType: 6,
//       nameMin: "",
//       nameMax: "",
//       valueMax: "",
//     },
//   ],
// };

export interface ProductFilterContextModel {
  productFilterData: ProductFilterBindingModel;
  setProductFilterData: Function;
}

export interface SourceKeyContextModel {
  sourceKeyValue: string;
  setSourceKey: Function;
}

export interface DistributorContextModel {
  distributor: string;
  setDistributor: Function;
  setDistributorLoading: Function;
}

export const ProductFilterContext =
  React.createContext<ProductFilterContextModel | null>(null);

export const useProductFilterContext = () =>
  React.useContext(ProductFilterContext);

export const SourceKeyContext =
  React.createContext<SourceKeyContextModel | null>(null);

export const useSourceKeyContext = () => React.useContext(SourceKeyContext);

export const DistributorContext =
  React.createContext<DistributorContextModel | null>(null);

export const useDistributorContext = () => React.useContext(DistributorContext);

const productFilterReturnSample: ProductFilterReturn = {
  displayName: "Resistors",
  key: "RESISTOR",
  totalCnt: 840,
  pageCnt: 17,
  pageSize: 50,
  pageCur: 1,
  filterList: [
    {
      key: "1",
      cellCnt: 2,
      cellList: [
        {
          key: "1",
          id: 1,
          name: "Series",
          displayName: "Series",
          cellCss: "col-md-4",
          minName: "",
          maxName: "",
          group: "Series",
          type: 6,
          unit: "",
          optionMin: "",
          optionMax: "",
          optionStep: null,
          isApplied: false,
          appliedValue: "",
          appliedUnitValue: "",
          optionList: [
            {
              key: "1_0",
              name: "Any",
              value: "",
              isEnabled: true,
              isSelected: true,
            },
            {
              key: "1_1",
              name: "1900",
              value: "1900",
              isEnabled: true,
              isSelected: false,
            },
            {
              key: "1_2",
              name: "3810",
              value: "3810",
              isEnabled: true,
              isSelected: false,
            },
            {
              key: "1_3",
              name: "4500",
              value: "4500",
              isEnabled: true,
              isSelected: false,
            },
          ],
          unitList: [],
        },
        {
          key: "2",
          id: 2,
          name: "Mounting",
          displayName: "Mounting",
          cellCss: "col-md-4",
          minName: "",
          maxName: "",
          group: "Mounting",
          type: 6,
          unit: "",
          optionMin: "",
          optionMax: "",
          optionStep: null,
          isApplied: false,
          appliedValue: "",
          appliedUnitValue: "",
          optionList: [
            {
              key: "2_0",
              name: "Any",
              value: "",
              isEnabled: true,
              isSelected: true,
            },
            {
              key: "2_1",
              name: "Heatsink",
              value: "Heatsink",
              isEnabled: true,
              isSelected: false,
            },
            {
              key: "2_2",
              name: "Non-PCB",
              value: "Non-PCB",
              isEnabled: true,
              isSelected: false,
            },
            {
              key: "2_3",
              name: "SMD - adhesive",
              value: "SMD - adhesive",
              isEnabled: true,
              isSelected: false,
            },
          ],
          unitList: [],
        },
        {
          key: "3",
          id: 3,
          name: "R",
          displayName: "R",
          cellCss: "col-md-4",
          minName: "RMin",
          maxName: "RMax",
          group: "R",
          type: 21,
          unit: "Ω",
          optionMin: "",
          optionMax: "",
          optionStep: null,
          isApplied: false,
          appliedValue: "",
          appliedUnitValue: "1",
          optionList: [],
          unitList: [
            {
              key: "3_1",
              name: "mΩ",
              value: ".001",
              isEnabled: true,
              isSelected: false,
            },
            {
              key: "3_2",
              name: "Ω",
              value: "1",
              isEnabled: true,
              isSelected: false,
            },
            {
              key: "3_3",
              name: "kΩ",
              value: "1000",
              isEnabled: true,
              isSelected: false,
            },
          ],
        },
      ],
    },
  ],
  headerList: [
    { name: "Product Image", key: "ProductImage", isSortEnabled: false },
    { name: "Part", key: "ProductCode", isSortEnabled: true },
    { name: "Description", key: "ProductName", isSortEnabled: false },
    { name: "Approval", key: "Approval", isSortEnabled: true },
    { name: "LEV", key: "LEV", isSortEnabled: true },
    { name: "Pr", key: "Pr", isSortEnabled: true },
    { name: "R", key: "RMin", isSortEnabled: true },
    { name: "Datasheet", key: "Datasheet", isSortEnabled: false },
  ],
  productList: [
    {
      key: "10",
      productCode: "1600",
      cellList: [
        {
          key: "10_1",
          type: "image",
          name: "Product Image",
          value: "/ttelectronics/media/productimages/resistors/1600-1900.jpg",
          target: "",
          url: "",
        },
        {
          key: "10_2",
          type: "link",
          name: "Part",
          value: "1600",
          target: "_self",
          url: "/products/categories/resistors/resistors/1600/",
        },
        {
          key: "10_3",
          type: "text",
          name: "Description",
          value: "Tubular vitreous enamelled wirewound resistor",
          target: "",
          url: "",
        },
        {
          key: "10_4",
          type: "text",
          name: "Approval",
          value: "",
          target: "",
          url: "",
        },
        {
          key: "10_5",
          type: "text",
          name: "LEV",
          value: "600V",
          target: "",
          url: "",
        },
        {
          key: "10_6",
          type: "text",
          name: "Pr",
          value: "21W",
          target: "",
          url: "",
        },
        {
          key: "10_7",
          type: "text",
          name: "R",
          value: "1Ω - 56kΩ",
          target: "",
          url: "",
        },
        {
          key: "10_8",
          type: "link",
          name: "Datasheet",
          value: "[i]",
          target: "_blank",
          url: "[i]",
        },
      ],
    },
  ],
};
