import React from "react";

export const Loading = ({ hide = false }) => {
  if (hide) {
    return null;
  }
  return (
    <section id="loading">
      <div className="container my-lg-9 my-md-7 my-6">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-center">
              <div
                className="spinner-border text-primary"
                role="status"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
            <div className="text-center mt-3">
              <span className="text-uppercase text-secondary loading-text">
                Loading...
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
