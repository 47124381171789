import React from "react";
import { VideoCardResult } from "./VideoCardResult";
import { VideoReturn } from "../VideoReturn";

interface Props {
  results?: VideoReturn;
}

export const VideoResults = ({ results }: Props) => {
  if (results && results?.totalCnt > 0) {
    return (
      <section id="variable">
        <div className="container mt-lg-9 mt-md-7 mt-6 news-events-listing video-card">
          <div className="row">
            {results.resultList.map((r) => {
              return <VideoCardResult key={r.key} card={r} />;
            })}
          </div>
        </div>
      </section>
    );
  }

  return (
    <div className="justify-content-center text-center mt-5">
      <h3 className="text-secondary">Sorry, No Results Were Found</h3>
    </div>
  );
};
