import React, { useEffect } from "react";
import {
  ProductFilterReturnFilterCell,
  ProductFilterBindingFilter,
  useProductFilterContext,
  ProductFilterContextModel,
  useSourceKeyContext,
  SourceKeyContextModel,
} from "../ProductFilterReturn";

interface Props {
  data: ProductFilterReturnFilterCell;
}

export const ProductFilterInputRedirect = ({ data }: Props) => {
  const initialSelectValue = data?.optionList?.find((o) => o.isSelected);
  return (
    <div className="mb-3 d-flex">
      <div className="flex-grow-1 w-100">
        <label className="form-label">{data.displayName}</label>
        <select
          className="form-control"
          value={initialSelectValue?.value}
          onChange={(e) => {
            window.location.pathname = e.target.value;
          }}
        >
          {data.optionList.map((d) => (
            <option value={d.value} key={d.key} disabled={!d.isEnabled}>
              {d.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
