import React, { useEffect } from "react";
import {
  ProductFilterReturnFilterCell,
  ProductFilterBindingFilter,
  useProductFilterContext,
  ProductFilterContextModel,
} from "../ProductFilterReturn";

interface Props {
  data: ProductFilterReturnFilterCell;
}

export const ProductFilterInputRange = ({ data }: Props) => {
  const { productFilterData, setProductFilterData } =
    useProductFilterContext() as ProductFilterContextModel;

  const [filterValue, setFilterValue] = React.useState<string>("");
  const [unitFilterValue, setUnitFilterValue] = React.useState<string>("1");

  useEffect(() => {
    const existingFilter = productFilterData.FilterList.find(
      (f) => f.name === data.group
    );

    const currentFilterValue = existingFilter?.rawValue || "";
    const currentFilterUnitValue = existingFilter?.unitValue || "1";

    setFilterValue(currentFilterValue);
    setUnitFilterValue(currentFilterUnitValue);
  }, [productFilterData, data]);

  const existingFilter = () => {
    return (
      productFilterData &&
      productFilterData.FilterList.find((f) => f.name === data.group)
    );
  };

  const updateFilterList = () => {
    const existingValue = existingFilter()?.rawValue;
    if (
      (!existingValue && filterValue === "") ||
      existingValue === filterValue
    ) {
      return;
    }
    let newFilterList = [...productFilterData.FilterList];
    if (existingFilter() && filterValue !== "") {
      newFilterList = productFilterData.FilterList.map(
        (f: ProductFilterBindingFilter) => {
          if (f.name === data.group) {
            return {
              ...f,
              value:
                data.unitList.length > 0
                  ? (+filterValue * +unitFilterValue).toString()
                  : filterValue,
              rawValue: filterValue,
            };
          }
          return f;
        }
      );
    } else {
      if (filterValue !== "") {
        const newFilter: ProductFilterBindingFilter = {
          name: data.group,
          nameMin: data.minName,
          nameMax: data.maxName,
          value:
            data.unitList.length > 0
              ? (+filterValue * +unitFilterValue).toString()
              : filterValue,
          rawValue: filterValue,
          valueMax: "",
          filterType: data.type,
        };
        newFilterList.push(newFilter);
      } else {
        newFilterList = newFilterList.filter(
          (f: ProductFilterBindingFilter) => f.name !== data.group
        );
      }
    }

    setProductFilterData({
      ...productFilterData,
      FilterList: newFilterList,
      PageNumber: 0,
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        updateFilterList();
      }}
    >
      <div className="mb-3 d-flex text-magnitude-container">
        <div className="flex-grow-1 w-100">
          <label className="form-label">{data.displayName}</label>
          <input
            type="number"
            id={data.group}
            step="any"
            className="form-control"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            onBlur={updateFilterList}
          />
        </div>
        {data.unitList.length > 0 && (
          <div className="flex-shrink-1">
            <label className="form-label">&nbsp;</label>
            <select
              className="form-select"
              value={unitFilterValue}
              onChange={(e) => {
                setUnitFilterValue(e.target.value);
                let newFilterList = [...productFilterData.FilterList];
                if (existingFilter() && filterValue !== "") {
                  newFilterList = productFilterData.FilterList.map(
                    (f: ProductFilterBindingFilter) => {
                      if (f.name === data.group) {
                        return {
                          ...f,
                          value: (+filterValue * +e.target.value).toString(),
                          unitValue: e.target.value,
                        };
                      }
                      return f;
                    }
                  );
                } else {
                  if (filterValue !== "") {
                    const newFilter: ProductFilterBindingFilter = {
                      name: data.group,
                      nameMin: data.minName,
                      nameMax: data.maxName,
                      value: (+filterValue * +e.target.value).toString(),
                      valueMax: "",
                      filterType: data.type,
                    };
                    newFilterList.push(newFilter);
                  } else {
                    newFilterList = newFilterList.filter(
                      (f: ProductFilterBindingFilter) => f.name !== data.group
                    );
                  }
                }

                setProductFilterData({
                  ...productFilterData,
                  FilterList: newFilterList,
                  PageNumber: 0,
                });
              }}
            >
              {data.unitList.map((d) => (
                <option value={d.value} key={d.key}>
                  {d.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </form>
  );
};
