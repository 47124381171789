import React from "react";
import { ResourceBindingModel, ResourceReturnResult } from "../ResourceReturn";

interface Props {
  data: ResourceReturnResult[];
  resourceFilterData: ResourceBindingModel;
  setResourceFilterData: Function;
}

export const ResourceTable = ({
  data,
  resourceFilterData,
  setResourceFilterData,
}: Props) => {
  const sortIcon = () => {
    const sort = resourceFilterData.sort;
    if (!sort) {
      return (
        <span
          className="sort-header sort-hover"
          onClick={() => {
            setResourceFilterData({
              ...resourceFilterData,
              sort: "ASC",
            });
          }}
        >
          <i className="bi bi-caret-up-fill"></i>
        </span>
      );
    }
    if (sort === "ASC") {
      return (
        <span
          className="sort-header"
          onClick={() => {
            setResourceFilterData({
              ...resourceFilterData,
              sort: "DESC",
            });
          }}
        >
          <i className="bi bi-caret-down-fill"></i>
        </span>
      );
    }
    return (
      <span
        className="sort-header sort-hover"
        onClick={() => {
          setResourceFilterData({
            ...resourceFilterData,
            sort: undefined,
          });
        }}
      >
        <i className="bi bi-caret-up"></i>
        <i className="bi bi-caret-down"></i>
      </span>
    );
  };

  if (!data.length) {
    return null;
  }

  return (
    <div className="table-responsive">
      <table className="table resource-table">
        <thead>
          <tr>
            <th>File</th>
            <th>Title {sortIcon()}</th>
            <th>Type</th>
            <th>Brand &amp; Location</th>
            <th>Product &amp; Solutions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.key}>
              <td
                className={`file-icon ${item.iconClass}`}
                style={{ fontSize: "2em" }}
              ></td>
              <td data-tooltip title={item.resourceName}>
                {" "}
                <a href={item.fileVideoURL} target="_blank" rel="noreferrer">
                  {item.resourceName}
                </a>
              </td>
              <td>{item.typeDisplay}</td>
              <td>
                {" "}
                {item.brand && item.location
                  ? `${item.brand} | ${item.location}`
                  : `${item.brand || ""}${item.location || ""}`}
              </td>
              <td className="resource-description">{item.productDisplay}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
