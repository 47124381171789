import { http } from "./http";

export interface ResourceReturn {
  displayName: string;
  key: string;
  pageSize: number;
  pageCnt: number;
  pageCur: number;
  totalCnt: number;
  filter: ResourceReturnFilter;
  resultList: ResourceReturnResult[];
}

export interface ResourceReturnFilter {
  typeId?: number;
  subtypeId?: number;
  productId?: number;
  location?: number;
  keyword?: string;
  sortType?: string;
  typeOptionList: ResourceReturnFilterOption[];
  subtypeOptionList: ResourceReturnFilterOption[];
  productOptionList: ResourceReturnFilterOption[];
  locationOptionList: ResourceReturnFilterOption[];
  sortOptionList: ResourceReturnFilterOption[];
}

export interface ResourceReturnFilterOption {
  key: string;
  name: string;
  value: string;
  isEnabled: boolean;
  isSelected: boolean;
}

export interface ResourceReturnResult {
  key: string;
  resourceName: string;
  brand: string;
  cardImage: string;
  fileVideoURL: string;
  location: string;
  typeDisplay: string;
  productDisplay: string;
  mediaType: string;
  iconClass: string;
}

export interface ResourceBindingModel {
  resourceTypeCode?: string;
  typeCategoryId?: number;
  subtypeCategoryId?: number;
  productCategoryId?: number;
  location?: string;
  keyword?: string;
  sort?: string;
  pageSize?: number;
  pageNumber?: number;
}

export const getResourceReturnAsync = async (
  binding: ResourceBindingModel
): Promise<ResourceReturn | undefined> => {
  const result = await http<ResourceReturn>({
    path: `/search/resource?typ=${binding.typeCategoryId}&subtyp=${
      binding.subtypeCategoryId || ""
    }&prod=${binding.productCategoryId || ""}&loc=${
      binding.location || ""
    }&kywrd=${binding.keyword || ""}&srt=${binding.sort || ""}&pgsz=${
      binding.pageSize || ""
    }&pgnum=${binding.pageNumber || ""}`,
    method: "GET",
  });
  if (result.ok && result.body) {
    return result.body;
  } else {
    return undefined;
  }
};

export const resourceBinding: ResourceBindingModel = {
  resourceTypeCode: undefined,
  typeCategoryId: undefined,
  subtypeCategoryId: undefined,
  productCategoryId: undefined,
  location: undefined,
  keyword: undefined,
  sort: undefined,
  pageSize: 50,
  pageNumber: 0,
};
